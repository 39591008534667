// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
// require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// require("trix");
require("@rails/actiontext");

$(function() {

  $('div.segment h3 a').on('click', function(e){
    e.preventDefault();
    
    if($(this).closest('div.segment').hasClass('segment-open')) {
      $(this).closest('div.segment').removeClass('segment-open');
    } else {
      $(this).closest('div.segment').addClass('segment-open');
    }
  });


  $('.search-group h5 a').on('click', function(e){
    e.preventDefault();
    
    if($(this).closest('div.search-group').hasClass('search-group-collapsed')) {
      $(this).closest('div.search-group').removeClass('search-group-collapsed');
    } else {
      $(this).closest('div.search-group').addClass('search-group-collapsed');
    }
  });

  $('.multi-select').each(function(i, obj) {
    _container = $(this);

    if(_container.find('input:checked').length > 0) {
      $(_container).find('span').remove();

      $(this).find('input:checked').each(function(c, chk) {
        $('<span />').html($(this).closest('label').data('value')).appendTo(_container);
      });
    }
  });

  $('.multi-select').on('click', function(e) {
    if($(this).hasClass('active')){
      $(this).removeClass('active');
    } else {
      $('.multi-select').removeClass('active');
      $(this).addClass('active');
    }
  });

  $('.multi-select input').on('click', function(e) {
    _input = $(this);
    _container = _input.closest('.multi-select');

    if(_input.is(':checked')) {
      if(_container.find('span.empty').length > 0) {
        $(_container.find('span')).remove();
        $('<span />').html(_input.closest('label').data('value')).prependTo(_container);
      } else {
        $('<span />').html(_input.closest('label').data('value')).insertAfter(_container.find('span').last());
      }
    } else {
      _container.find('span').each(function(){
        if($(this).text() == _input.closest('label').data('value')) {
          $(this).remove();
        }
      });
      if(_container.find('span').length == 0) {
        $('<span />', {class: 'empty'}).html('None Selected').prependTo(_container);
      }
    }
  });
  
  // Disable dropdown trigger
  $(".activate").on("click", function(e) {
    e.preventDefault();
  });

  // Show the user-menu partial
  $("a.trigger-user-menu").on("click", function(e) {
    e.preventDefault();
    $("div.user-menu").css("display", "flex");
  });

  // Hide the user menu

  $(".user-menu a:last").on("click", function(e) {
    e.preventDefault();
    $("div.user-menu").css("display", "none");
  });

  $(document).keyup(function(e) {
    if (e.key === "Escape") {
      // Hide the user menu
      $("div.user-menu").css("display", "none");

      // Hide Modals
      $(".modal").fadeOut();

      // Hide Multiselects
      $('.multi-select').removeClass('active');
    }
  });

  // Show the modal
  $(".modal-show").on("click", function(e) {
    e.preventDefault();
    $($(this).attr("href"))
      .fadeIn()
      .show();
  });

  $(".modal a.close").on("click", function(e) {
    e.preventDefault();
    $(".modal").fadeOut();
  });

  // Tabs
  $(".tabs a").on("click", function(e) {
    e.preventDefault();

    $('.tabs a').removeClass('active');

    $(this).addClass('active');

    var target = $(this).attr("href");

    $(".tab").addClass("is-hidden");

    $(target).removeClass("is-hidden");

    if($(this).is(':last-child')) {
      $(this).closest('form').find('.btn-next').html('Save Listing');
    } else {
      $(this).closest('form').find('.btn-next').html('Next');
    }
  });

  // Tab Form Submission Button 

  // $('.new-listing-form a.btn-next').on('click', function(e){
  //   e.preventDefault();

  //   var current_tab = $('.new-listing-form .tabs a.active');

  //   if(current_tab.is(':last-child')) {
  //     current_tab.closest('form').submit();
  //   } else {
  //     if(current_tab.next().is(':last-child')) {
  //       $(this).html('Save Listing');
  //     }
  //     current_tab.next().click();
  //   }
  // });




  // $('.message a').on('click', function(e) {
  // 	e.preventDefault();

  // 	$('div.message').removeClass('message-active');

  // 	$(this).removeClass('bold');
  // 	$(this).closest('div.message').addClass('message-active');
  // 	$.get($(this).attr('href'), function(resp){
  // 		$('#message-content').html(resp);
  // 	});
  // });
});

// $(function() {

// 	// Show the inline search dialog

// 	// // Hide Toasts
// 	// $('.notification .delete').click(function(e){
// 	// 	e.preventDefault();
// 	// 	$(this).parent().remove();
// 	// });

// 	// $('article.message .delete').on('click', function(e){
// 	// 	e.preventDefault();
// 	// 	$(this).closest('.message').remove();
// 	// });

// 	// Navigate Steps
// 	$('.new-project .tabs li').click(function(e){
// 		e.preventDefault();
// 		var target = $(this).data('target');
// 		$(this).parent('ul').find('li').removeClass('is-active');
// 		$(this).addClass('is-active');
// 		$('div.step').addClass('is-hidden');
// 		$('div.' + target).removeClass('is-hidden');
// 	});

// 	// Screenshot Upload
// 	$('#screenshot-upload input[type=file]').on('change', function(e) {
// 		if(e.target.files.length > 0) {
// 			$('#screenshot-upload .file-name').html(e.target.files[0].name);
// 		}
// 	});

// 	// Asset Upload
// 	$('#asset-upload input[type=file]').on('change', function(e) {
// 		if(e.target.files.length > 0) {
// 			$('#asset-upload .file-name').html(e.target.files[0].name)
// 		}
// 	});

// 	$('.next-step').click(function(e){
// 		e.preventDefault();
// 		$('.new-project .tabs ul li.is-active').next().click();
// 	});

// 	$('.powerup form :input').on('change', function(){

// 		// $('.powerup form :input').each(function(){

// 			var total = 0;

// 			// total += $('select [name="promote"] option:selected').data('price');
// 			total = total + parseFloat($('select[name="promote"] option:selected').data('price'));

// 			// total = total + parseFloat($('checkbox[name="unlocked"]:checked').data('price'));

// 			$('.price-tag').text("$" + total.toFixed(2));
// 			// <select name="promote">
// 			// children("option:selected"). val();

// 		// });
// 		// alert($('.price-tag').data('price'));

// 	});

// 	// Subscriber

// 	$( "form.subscription" ).submit(function( event ) {
//   	// alert( "Handler for .submit() called." );
//   	// alert(2);
//   	// event.preventDefault();
//   	// alert(1);
//   	// $(event).addClass('is-loading');
//   	// is-loading
// })

// 	// $(form.subscription)

// 	// $("form :input").change(function() {
// 	//   $(this).closest('form').data('changed', true);
// 	// });
// });
